import React from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import { invoiceListClearFilters, invoiceListFetch, invoiceListSetPage, invoiceListUnload } from '../../lib/redux/actions/invoices';
import MASpinner from '../../components/common/MASpinner';
import Alert from 'react-bootstrap/Alert';
import Paginator from '../../components/common/Paginator';
import InvoiceListTable from './InvoiceListTable';
import BulkAssignInvoicesForm from '../../components/forms/Invoice/BulkAssignInvoicesForm';
import InvoiceTableFilterForm from '../../components/forms/Invoice/InvoiceTableFilterForm';
import { isChaserOutsourcer, isStaff } from '../../lib/helper/authorisation';
import { addToast } from '../../lib/redux/actions/toast';
import { getFiltersFromSession } from '../../lib/routing/filters';
import AppliedFiltersOutput from '../../components/common/AppliedFiltersOutput';

const mapStateToProps = (state) => ({
  ...state.invoices,
  ...state.stats,
});

const mapDispatchToProps = {
  invoiceListFetch,
  invoiceListSetPage,
  invoiceListUnload,
  invoiceListClearFilters,
  addToast,
};

class InvoiceListContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showFilters: false,
      totalInvoicesBulkSelected: 0,
      bulkSelectedInvoices: [],
      revisitOrderBy: null,
    };
    const qs = new URLSearchParams(props.location.search);
    if (qs.has('assignedTo')) {
      this.props.filters['assignedTo[]'] = qs.get('assignedTo');
    }
  }

  getCombinedFilters() {
    const { filters, defaultFilters = {}, match } = this.props;
    let filtersFromSession = getFiltersFromSession(match.path, 'json');
    let returnFilters = { ...defaultFilters, ...filters, ...filtersFromSession };
    if (filtersFromSession && filtersFromSession['outstandingBalance(gte)'] !== "undefined") {
      returnFilters['outstandingBalance(gte)'] = filtersFromSession['outstandingBalance(gte)'];
    }
    if (filtersFromSession && filtersFromSession['outstandingBalance(lte)'] !== "undefined") {
      returnFilters['outstandingBalance(lte)'] = filtersFromSession['outstandingBalance(lte)'];
    }

    if (returnFilters['assignedTo']) {
      delete returnFilters['exists(assignedTo)'];
    }

    if (returnFilters['payorType']) {
      delete returnFilters['payorType.code'];
    }
    if (this.state.revisitOrderBy) {
      delete returnFilters['order[consultant.code]'];
      delete returnFilters['order[dateOfLastContact]'];
      delete returnFilters['order[patientLastName]'];
      returnFilters['order[revisitDate]'] = this.state.revisitOrderBy;
    }
    return returnFilters;
  }

  componentWillUnmount() {
    let persistFilters = this.props.match.path.includes('/invoices/:page');
    this.props.invoiceListUnload(persistFilters);
  }

  componentDidMount() {
    this.loadInvoices();
  }

  loadInvoices(successMessage = false) {
    this.setState({
      totalInvoicesBulkSelected: 0,
      //      bulkSelectedInvoices: [],
    });
    this.props.invoiceListFetch(this.getQueryParamPage(), this.getCombinedFilters()).then(() => {
      if (successMessage !== false) {
        this.props.addToast('Invoices Assigned Successfully', true);
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { currentPage, invoiceListFetch, invoiceListSetPage, defaultFilters, filters, invoiceListUnload, invoiceListClearFilters } = this.props;
    let persistFilters = this.props.match.path.includes('/invoices/:page');
    if (prevProps.defaultFilters !== defaultFilters) {
      invoiceListUnload(persistFilters);
      if (!persistFilters) {
        invoiceListClearFilters();
      }
      this.loadInvoices();
      this.setState(() => {
        return { showFilters: false };
      });
    }

    if (prevProps.match.params.page !== this.getQueryParamPage()) {
      invoiceListSetPage(this.getQueryParamPage());
    }
    if (prevProps.currentPage !== currentPage || prevProps.filters !== filters) {
      invoiceListFetch(currentPage, this.getCombinedFilters());
    }
  }

  orderByRevisitDay() {
    const { currentPage, invoiceListFetch } = this.props;
    let revisitOrder = !this.state.revisitOrderBy ? 'ASC' : this.state.revisitOrderBy === 'ASC' ? 'DESC' : null;
    this.setState(
      {
        revisitOrderBy: revisitOrder,
      },
      () => {
        let filters = this.getCombinedFilters();
        invoiceListFetch(currentPage, filters);
      },
    );
  }

  getQueryParamPage() {
    return Number(this.props.match.params.page) || 1;
  }

  changePage(page) {
    const { history, invoiceListSetPage, path } = this.props;
    invoiceListSetPage(page);
    history.push(path.replace(':page', page));
  }

  nextPage() {
    const { currentPage, pageCount } = this.props;
    const newPage = Math.min(currentPage + 1, pageCount);
    this.changePage(newPage);
  }

  prevPage() {
    const { currentPage } = this.props;
    const newPage = Math.max(currentPage - 1, 1);
    this.changePage(newPage);
  }

  toggleFilters() {
    this.setState((prevState) => {
      return { showFilters: !prevState.showFilters };
    });
  }

  autoBulkSelectInvoices(count) {
    const { invoices } = this.props;

    this.setState({
      totalInvoicesBulkSelected: 0,
      bulkSelectedInvoices: [],
    });

    let invoicesToSelect = [];
    let newTotal = 0;

    invoices.slice(0, count).map((invoice) => {
      invoicesToSelect.push(invoice.id);
      newTotal++;
      return newTotal;
    });

    this.setState({
      totalInvoicesBulkSelected: newTotal,
      bulkSelectedInvoices: invoicesToSelect,
    });
  }

  invoiceBulkSelected(invoice) {
    const { totalInvoicesBulkSelected, bulkSelectedInvoices } = this.state;
    let invoicesToSelect = bulkSelectedInvoices;

    let newTotal;
    // if already selected, remove it
    if (bulkSelectedInvoices.some((invoiceId) => invoiceId === invoice.id)) {
      invoicesToSelect = invoicesToSelect.filter((invoiceId) => invoiceId !== invoice.id);
      newTotal = totalInvoicesBulkSelected - 1;
    } else {
      // otherwise add it
      invoicesToSelect.push(invoice.id);
      newTotal = totalInvoicesBulkSelected + 1;
    }
    this.setState({
      totalInvoicesBulkSelected: newTotal,
      bulkSelectedInvoices: invoicesToSelect,
    });
  }

  render() {
    const {
      invoices,
      stats,
      isFetchingInvoices,
      pageTitle,
      pageType,
      pageCount,
      currentPage,
      showBulkSelect = false,
      enableBulkAssign = false,
      defaultFilters = {},
      filterOverrides = {},
    } = this.props;
    const { totalInvoicesBulkSelected = 0, bulkSelectedInvoices = [] } = this.state;
    let invoicesLeftClass = 'badge badge-pill badge-danger mr-3';
    return (
      <Card>
        <Card.Header>
          <div className="d-flex justify-content-between">
            {' '}
            {pageTitle}
            {/*{invoices && invoices.length > 0 &&*/}
            <div>
              {pageType === 'bulk' && stats.creditControlInvoicesUnassigned && stats.creditControlInvoicesUnassigned > 0 ? (
                <span className={invoicesLeftClass} title="Invoices to assign">
                  {stats.creditControlInvoicesUnassigned}
                </span>
              ) : null}
              {pageType === 'all' && (isChaserOutsourcer(true) || isStaff(true)) && stats.invoicesCurrentlyAssigned && stats.invoicesCurrentlyAssigned > 0 ? (
                <span className={invoicesLeftClass} title="Invoices to process">
                  {stats.invoicesCurrentlyAssigned}
                </span>
              ) : null}
              <button className="btn btn-sm btn-outline-secondary mr-3" onClick={this.toggleFilters.bind(this)}>
                Filters
              </button>
              <span className="tabular-data-result-count">{pageCount} pages</span>
              {isFetchingInvoices && <MASpinner wrapperClasses="ml-3 float-right" size="sm" />}
            </div>
            {/*}*/}
          </div>
        </Card.Header>

        {this.state.showFilters && (
          <Card.Body className={'mediaccount-filter-wrapper'}>
            <InvoiceTableFilterForm
              defaultFilters={defaultFilters}
              filterOverrides={filterOverrides}
              setPage={this.changePage.bind(this)}
              pageType={pageType}
              location={this.props.match.path}
            />
          </Card.Body>
        )}

        {showBulkSelect && enableBulkAssign && (
          <BulkAssignInvoicesForm
            showNextDayCount={false}
            invoiceIdsToAssign={bulkSelectedInvoices}
            numberOfInvoices={totalInvoicesBulkSelected}
            loadInvoices={this.loadInvoices.bind(this)}
            autoBulkSelectAction={this.autoBulkSelectInvoices.bind(this)}
          />
        )}

        {(invoices === null || invoices.length === 0) && (
          <Card.Body>
            <Alert variant={'info'} className="mb-0">
              No Invoices found
            </Alert>
          </Card.Body>
        )}

        <AppliedFiltersOutput/>

        {invoices && invoices.length > 0 && (
          <InvoiceListTable
            invoices={invoices}
            showBulkSelect={showBulkSelect}
            bulkSelectedInvoices={bulkSelectedInvoices}
            bulkSelectAction={this.invoiceBulkSelected.bind(this)}
            pageType={pageType}
            orderByRevisitDay={this.orderByRevisitDay.bind(this)}
            revisitOrderBy={this.state.revisitOrderBy}
          />
        )}
        {invoices && invoices.length > 0 && (
          <Paginator
            currentPage={currentPage}
            pageCount={pageCount}
            setPage={this.changePage.bind(this)}
            nextPage={this.nextPage.bind(this)}
            prevPage={this.prevPage.bind(this)}
          />
        )}
      </Card>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InvoiceListContainer);
