import Container from 'react-bootstrap/Container';
import { Col, Row } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import UpdateInsuranceDetailsForm from '../../components/forms/InsuranceDetails/UpdateInsuranceDetailsForm';
import { fetchPublicHealthInsurers } from '../../lib/redux/actions/publicHealthInsurers';
import { invoiceSingleGetByUniqueRefContactId } from '../../lib/redux/actions/invoices';
import { useHistory, useParams } from 'react-router-dom';
import * as ROUTES from '../../lib/routing/frontend';

function UpdateInsuranceDetailsContainer({ fetchPublicHealthInsurers, invoice, invoiceSingleGetByUniqueRefContactId, isFetchingInvoice, isFetchingPublicHealthInsurers }) {
  const { uniqueRef, contactId } = useParams();
   let history = useHistory();
  const [loadingStarted, setLoadingStarted] = useState(false);

  useEffect(() => {
    if (window && window.tidioChatApi) {
      window.tidioChatApi.hide();
    }
  },[])

  useEffect(() => {
    fetchPublicHealthInsurers();
    invoiceSingleGetByUniqueRefContactId(uniqueRef, contactId);
    if (window && window.tidioChatApi) {
      window.tidioChatApi.hide();
    }
  }, [fetchPublicHealthInsurers, invoiceSingleGetByUniqueRefContactId, uniqueRef, contactId]);

  useEffect(() => {
    if (!loadingStarted && isFetchingInvoice) {
      setLoadingStarted(true);
    }
    if ( loadingStarted && !isFetchingInvoice && invoice === null) {
      history.push(ROUTES.NOT_FOUND);
    }
  }, [isFetchingInvoice, loadingStarted, invoice, history]);

  return <Container>
    <Row className="mt-4">
      <Col>
        {isFetchingPublicHealthInsurers || isFetchingInvoice ? <p>Loading ...</p> : (
            <UpdateInsuranceDetailsForm/>
        )}
      </Col>
    </Row>
  </Container>;
}

const mapStateToProps = (state) => ({
  ...state.invoices,
  ...state.publicHealthInsurers,
});

const mapDispatchToProps = {
  fetchPublicHealthInsurers,
  invoiceSingleGetByUniqueRefContactId,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(UpdateInsuranceDetailsContainer);


